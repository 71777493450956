.userList {
	max-width: 300px;
}

.userList ul {
	list-style-type: none;
	padding: 0;
}

.userList p {
	font-weight: bold;
	font-size: 20px;
}