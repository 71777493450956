.messageList {
	border: red;
	flex: 1;
	overflow: scroll;
	box-sizing: border-box;
	border-width: 3px;
	border-style: solid;
	border-color: #ccccff;
}

.messageList ul {
	list-style-type: none;
	text-align: left;
	padding: 0;
    margin: 0;
}

.messageList p {
	font-weight: bold;
	font-size: 20px;
}